import { Action } from "../actionTypes";

const initState = {
    AllUser: [],
    AllReferrals: [],
    AllOfferCodes: [],
    offerCode: {},
    referral: {},
    UserById: [],
    downloaduser: [],
    error: "",
};

const store = (state = initState, action: any) => {
    switch (action.type) {
        case Action.ADMIN_GET_OFFERCODES_INIT:
            return {
                ...state
            }
        case Action.ADMIN_GET_OFFERCODES_SUCCESS:
            return {
                ...state,
                AllOfferCodes: action?.payload
            }
        case Action.ADMIN_GET_OFFERCODES_FAIL:
            return {
                ...state,
                error: action?.payload
        }
        case Action.ADMIN_ADD_OFFERCODE_INIT:
            return {
                    ...state
            }
        case Action.ADMIN_ADD_OFFERCODE_SUCCESS:
            return {
                    ...state,
                    offerCode: action?.payload
            }
        case Action.ADMIN_ADD_OFFERCODE_FAIL:
                return {
                    ...state,
                    error: action?.payload
                }
        case Action.ADMIN_DELETE_OFFERCODE_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.ADMIN_DELETE_OFFERCODE_INIT:
                return {
                        ...state
                }
        case Action.ADMIN_DELETE_OFFERCODE_SUCCESS:
                return {
                        ...state,
                        offerCode: action?.payload
                }
        case Action.ADMIN_UPDATE_OFFERCODE_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.ADMIN_UPDATE_OFFERCODE_INIT:
                return {
                        ...state
                }
        case Action.ADMIN_UPDATE_OFFERCODE_SUCCESS:
                return {
                        ...state,
                        offerCode: action?.payload
                }
       
      
        case Action.ADMIN_GET_REFERRALS_INIT:
            return {
                ...state
            }
        case Action.ADMIN_GET_REFERRALS_SUCCESS:
            return {
                ...state,
                AllReferrals: action?.payload
            }
        case Action.ADMIN_GET_REFERRALS_FAIL:
            return {
                ...state,
                error: action?.payload
            }

        case Action.ADMIN_UPDATE_REFERRAL_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.ADMIN_UPDATE_REFERRAL_INIT:
                return {
                        ...state
                }
        case Action.ADMIN_UPDATE_REFERRAL_SUCCESS:
                return {
                        ...state,
                        referral: action?.payload
                }
        case Action.ADMIN_ADD_REFERRAL_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.ADMIN_ADD_REFERRAL_INIT:
                return {
                        ...state
                }
        case Action.ADMIN_ADD_REFERRAL_SUCCESS:
                return {
                        ...state,
                        referral: action?.payload
                }

        case Action.ADMIN_DELETE_REFERRAL_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.ADMIN_DELETE_REFERRAL_INIT:
                return {
                        ...state
                }
        case Action.ADMIN_DELETE_REFERRAL_SUCCESS:
                return {
                        ...state,
                        referral: action?.payload
                }

        case Action.ADMIN_GET_ALLUSER_INIT:
            return {
                ...state
            }
        case Action.ADMIN_GET_ALLUSER_SUCCESS:
            return {
                ...state,
                AllUser: action?.payload
            }
        case Action.ADMIN_GET_ALLUSER_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.GET_USERBY_ID_INIT:
            return {
                ...state
            }
        case Action.GET_USERBY_ID_SUCCESS:
            return {
                ...state,
                UserById: action?.payload
            }
        case Action.GET_USERBY_ID_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        case Action.ADMIN_GET_ALLUSER_DOWNLOAD_INIT:
            return {
                ...state
            }
        case Action.ADMIN_GET_ALLUSER_DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloaduser: action?.payload
            }
        case Action.ADMIN_GET_ALLUSER_DOWNLOAD_FAIL:
            return {
                ...state,
                error: action?.payload
            }
        default:
            return state;
    }
}
export default store;
