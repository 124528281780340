import { lazy, FC, Suspense, useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { OrderWrapper } from '../pages/dashboard/OrderWrapper'
import { MarkerWrapper } from '../pages/dashboard/MarkerWrapper'
import { getCSSVariableValue } from '../../_4threal/assets/ts/_utils'
import { WithChildren } from '../../_4threal/helpers'
import Changepassword from '../modules/profile/changepassword'
import Vrcards from '../pages/dashboard/vrcards'
import Analytics from '../pages/Analytics/Analytics'
import Admintools from '../pages/Admintools/AdminTools'
import Overview from '../pages/overview'
import ProductMenu from '../modules/cardform/components/editors/ProductMenu'
import ThreejsEditorWrapper from '../modules/cardform/components/editors/ThreejsEditorWrapper'
import { Headerlauyout } from '../../_4threal/layout/Headerlayout'
import { useDispatch } from 'react-redux'
import { getCards, getDeletedCards } from '../../redux/action/card'
import { MasterLayout } from '../../_4threal/layout/MasterLayout'
import TrashCard from '../pages/Trashcard/TrashCard'
import { useSelector } from 'react-redux'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const CreatevcardPage = lazy(() => import('../modules/cardform/CreatevcardPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const AnalyticsPage = lazy(() => import('../modules/analytics/AnalyticsPage'))
  const dispatch = useDispatch()
  //const [Cards, setCards] = useState([])
  const deletedcards = useSelector((state: any) => state.Card.DeletedCard)
  // const cards = useSelector((state: any) => state.Card.UserCard)
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getCards())
      .then((res) => {
        // AppLog('cards', cards);
        dispatch(getDeletedCards())
          .then((res) => {
            setIsLoading(false)
          }).catch((error) => {
            console.error('Failed to fetch cards', error);
          }).finally(() => {
            setIsLoading(false);
          });
      })
      .catch((error) => {
        console.error('Failed to fetch cards', error);
      })
  }, [dispatch]);


  if (isLoading) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
          {/* Pages */}
          {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
          <Route path='analytics' element={<Analytics />} />
          <Route path='vrcard' element={<Vrcards />} />
          <Route path='editor' element={<ThreejsEditorWrapper />} />
          {/* <Route path='templete' element={<Templatewrapper />} /> */}
          {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
          {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
          {/* <Route path='carddetail' element={<Carddetail />} /> */}
          {/* <Route path='Plan' element={<Plandetail />} /> */}

          {/* Lazy Modules */}
          <Route
            path='crafted/pages/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/changepassword/*'
            element={
              <SuspensedView>
                <Changepassword />
              </SuspensedView>
            }
          />
          <Route
            path='/vcard/*'
            element={
              <SuspensedView>
                <CreatevcardPage />
              </SuspensedView>
            }
          />
          <Route
            path='/analytics/*'
            element={
              <SuspensedView>
                <AnalyticsPage />
              </SuspensedView>
            }
          />
            <Route
            path='/tools'
            //element={Cards.length > 0 ? <Navigate to="/dashboard" replace /> : <ProductMenu />}
            element={<Admintools />}
          />
          <Route
            path='/analytics/overview/:id'
            element={
              <SuspensedView>
                <Overview />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route element={<Headerlauyout />}>
          <Route
            path='/editor'
            //element={Cards.length > 0 ? <Navigate to="/dashboard" replace /> : <ProductMenu />}
            element={<ThreejsEditorWrapper />}
          />

        </Route>
          {
            deletedcards?.length > 0 ?
              <Route
                path='/trashcard'
                element={
                  <SuspensedView>
                    <TrashCard />
                  </SuspensedView>
                }
              /> :
              <Route path="/trashcard" element={<Navigate to="/dashboard" />} />
          }

          <Route
            path='user/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />

          {/* Page Not Found */}
          {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
        </Route>

        {/* <Route > */}
        <>
          <Route element={<MasterLayout />}>
            <Route path="/dashboard" element={<DashboardWrapper />} />
          </Route>
        </>

        <>
          <Route element={<MasterLayout />}>
            <Route path="/markers" element={<MarkerWrapper />} />
          </Route>
        </>


        <Route element={<MasterLayout />}>
          <Route
            path='/orders'
            //element={Cards.length > 0 ? <Navigate to="/dashboard" replace /> : <ProductMenu />}
            element={<OrderWrapper />}
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path='/pmenu/:flow/:repeatId'
            //element={Cards.length > 0 ? <Navigate to="/dashboard" replace /> : <ProductMenu />}
            element={<ProductMenu />}
          />
        </Route>
        {/* <Route element={<Headerlauyout />}>
          <Route path='pmenu' element={<ProductMenu />} />
        </Route> */}

        {/* {
          Cards?.length === 0 &&
          <Route element={<Headerlauyout />}>
            <Route
              path='/vcard/*'
              element={
                <SuspensedView>
                  <CreatevcardPage />
                </SuspensedView>
              }
            />
          </Route>
        } */}
      </Routes>
    </>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
