/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useSelector } from 'react-redux'
import { getDeletedCards } from '../../../../../redux/action/card'
import { useDispatch } from 'react-redux'

const SidebarMenuMain = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector((state: any) => state.Auth?.authProfile?.userData?.protected?.isAdmin)
  const cards = useSelector((state: any) => state.Card.DeletedCard)

  useEffect(() => {
    dispatch(getDeletedCards())
  }, [])

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='bi bi-file-earmark'
        title='My XR Experiences'
        fontIcon='bi-app-indicator'
      />
      {isAdmin && <SidebarMenuItem
        to='/markers'
        icon='bi bi-qr-code-scan'
        title='My XR Markers'
        fontIcon='bi-qr-code-scan'
      />}
      <SidebarMenuItem
        to='/orders'
        icon='bi bi-box2'
        title='My Print Orders'
        fontIcon='bi-app-indicator'
      />
      {isAdmin && <SidebarMenuItem
        to='/editor'
        icon='bi bi-pencil-square'
        title='Scene Editor'
        fontIcon='bi-pencil-square'
      />}
      {isAdmin && <SidebarMenuItem
        to='/tools'
        icon='bi bi-tools'
        title='Admin Tools'
        fontIcon='bi-tools'
      />}
      {
        cards?.length > 0 &&
        <SidebarMenuItem
          to='/trashcard'
          icon='bi bi-trash3'
          title='Trash'
          fontIcon='bi-layers'
        />
      }

      {/* <div className='position-absolute bottom-0 flex-column-auto pt-2 pb-6 w-100'> */}
      {/* <PaymentWarning userdata={userdata} /> */}
      {/*<SidebarMenuItem to='/Plan' icon='bi bi-gear' fontIcon='' title='Learn with us' />*/}
      {/*
        <SidebarMenuItemWithSub to='/apps/chat' title='Settings' fontIcon='bi bi-gear' icon='bi bi-layout-split'>
          <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </SidebarMenuItemWithSub>
        */}
      {/* </div> */}
    </>
  )
}

export { SidebarMenuMain }
