import { useNavigate, useParams } from "react-router-dom";
import { Flow } from "./WalkthroughSteps";
import { useState } from "react";
import { useSelector } from "react-redux";

function MenuItem(props: any) {
  const { onLinkClick, hoverTarget, setHoverTarget } = props.shared
  const { url, img } = props
  let { title } = props

  if(title === undefined) {
    title = img
  }

  const itemStyle = 'col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-3 py-3 cur-pointer'

  return (
    <div className={itemStyle} onClick={() => onLinkClick(`${url}`)}>
      <div onMouseEnter={e => { setHoverTarget(title) }} onMouseLeave={e => { setHoverTarget('none') }}>
        <img style={{ maxHeight: '200px' }} src={`/media/menu-products/${img}${hoverTarget === title ? '.gif' : '.png'}`} alt="" />
      </div>
      <div>{title}</div>
    </div>
  )
}

function ProductMenu() {
  const navigate = useNavigate()
  const { flow, repeatId } = useParams() // The current value at the end of the compiler URL
  const isAdmin = useSelector((state: any) => state.Auth?.authProfile?.userData?.protected?.isAdmin)
  const [hoverTarget, setHoverTarget] = useState('none')

  const getFlowName = () => Flow[flow || 'full']

  const onLinkClick = (markerType) => {
    if(repeatId === 'noid') { //create a new marker and experience
      navigate(`/vcard/compiler/${getFlowName()}/${markerType}/noid`)
    } else { //send the previous sceneId to the compiler
      navigate(`/vcard/compiler/${getFlowName()}/${markerType}/${repeatId}`)
    }
  }

  const shared = {
    onLinkClick: onLinkClick,
    hoverTarget: hoverTarget,
    setHoverTarget: setHoverTarget,
    isAdmin: isAdmin
  }

  return (
    <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-center'>
      { flow === Flow.repeat
      ? <img style={{ maxHeight: '120px', width: '100%' }} alt="" src='/media/menu-products/_MenuSelectAdditional.svg'/>
      : <img style={{ maxHeight: '120px', width: '100%' }} alt="" src='/media/menu-products/_MenuTitleHeader.svg' />
      }
      <div className='w-100 d-flex flex-column flex-sm-row align-items-center justify-content-center gap-4'>
        <div className='row' style={{ margin: '15px', fontSize: '15px', textAlign: 'center', paddingLeft: '10vw', paddingRight: '10vw', }}>
          <MenuItem shared={shared} url='bc'     img='Business Card' title='Business Card (2" x 3.5")'/>
          <MenuItem shared={shared} url='pc'     img='Postcard'      title='Postcard (4" x 6")'/>
          <MenuItem shared={shared} url='qr'     img='Sticker'/>
          <MenuItem shared={shared} url='custom' img='Coaster'/>
          <MenuItem shared={shared} url='custom' img='Hat'/>
          <MenuItem shared={shared} url='custom' img='Mug'/>
          <MenuItem shared={shared} url='custom' img='T-shirt'/>
          <MenuItem shared={shared} url='custom' img='Tumbler'/>
          {isAdmin &&
            <>
              <MenuItem shared={shared} url='pc2' img='Postcard' title='Mailer (5.5" x 8")'/>
              <MenuItem shared={shared} url='pc3' img='Postcard' title='Mailer (5.5" x 11")'/>
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default ProductMenu