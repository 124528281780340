/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
  getAllReferrals,
   getAllUser,
   getAllOfferCodes
} from '../../../redux/action/admin';
// import timeSince from '../../../utils/timesince';
import STable from '../../modules/cardform/components/helpers/STable';
import Modal from 'react-bootstrap/Modal'
import { validateLink, validatePhone, validateEmail, AppLog } from '../../../_4threal/helpers'
import Checkbox from '../../modules/cardform/components/helpers/Checkbox'


function Admintools() {
  const ngrokip:any = process.env.REACT_APP_NGROK_FRONTEND_URL;
  const testmode = localStorage.getItem('testmode')
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const referrals: any = useSelector((state: any) => state.Admin?.AllReferrals)
  // const token = localStorage.getItem('userId') //omitting due to eslint warning
  // const [dataMyTable, setdataMyTable] = useState<any>([]);
   const users: any = useSelector((state: any) => state.Admin?.AllUser)
   const offerCodes: any = useSelector((state: any) => state.Admin?.AllOfferCodes)
   const [limitRedemptions, setLimitRedemptions] = useState(false);
   const [limitStartDate, setLimitStartDate] = useState(false);
   const [limitEndDate, setLimitEndDate] = useState(false);
   const [productDiscountType, setProductDiscountType] = useState("percentage");
   const [subscriptionDiscountType, setSubscriptionDiscountType] = useState("percentage");
   const [modalType, setModalType] = useState('')
   const handleClose = () => setModalType('')
   const [referralFields, setReferralFields] = useState<any>({
    firstName: '',
    lastName: '',
    offer_code: 'beta',
    email: ''
  })

  const millistoDate = (millis: number) => {
    const date = new Date(millis);
    return date.toISOString();
  }

  const [referralErrors, setReferralErrors] = useState({
    firstName: false,
    lastName: false,
    email: false
  })

  const [offerCodeFields, setOfferCodeFields] = useState<any>({
    id: '',
    max_redemptions: 0,
    allow_in_checkout: false,
    product_discount: 0,
    product_discount_is_percent: true,
    subscription_discount: 0,
    subscription_discount_is_percent: true,
    valid_start:0,
    valid_end:0,
    cycles:0,
    trial_days:0,
    expires: true,
    is_live: true,
  })
  const [offerCodeErrors, setOfferCodeErrors] = useState({
    id: false,

  })

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const form = e.target.form
      const index = Array.prototype.indexOf.call(form, e.target)
      if (form.elements[index + 1]){
        form.elements[index + 1].focus()
      }
    }
  }

  const handleLimitRedemptions = () => {
    const value = !limitRedemptions
    if (value) {
      setOfferCodeFields({ ...offerCodeFields, ['max_redemptions']: 100 })
    }else{
      setOfferCodeFields({ ...offerCodeFields, ['max_redemptions']: 0 })
    }
    setLimitRedemptions(value)
  }

  const handleLimitDates = (type:string) => {
    const now = new Date();
   
    if (type === 'start') {
      const value = offerCodeFields?.valid_start || 0;
     
      if (value){
        setOfferCodeFields({ ...offerCodeFields, ['valid_start']: 0 })
      }else{
        const utcNow = now.toISOString();
        const nowMillis = now.getTime();
        setOfferCodeFields({ ...offerCodeFields, ['valid_start']: nowMillis })
      }
      setLimitStartDate(!value)
     } else if (type === 'end') {
      const value = offerCodeFields?.valid_end || 0;
      now.setMonth(now.getMonth() + 1);
      const utcOneMonthFromNow = now.toISOString();
      const nowMillis = now.getTime();
      setLimitEndDate(!limitEndDate)
      if (value){
        setOfferCodeFields({ ...offerCodeFields, ['valid_end']: 0 })
      }else{
        setOfferCodeFields({ ...offerCodeFields, ['valid_end']: nowMillis })
      }
    }
  }

  const handleAllowCheckout = () => {
    let ocf = {...offerCodeFields}
    ocf.allow_in_checkout = !offerCodeFields.allow_in_checkout;
    setOfferCodeFields(ocf)
  }

  const handleAddTrial = () => {
    let ocf = {...offerCodeFields}
    ocf.trial_days = offerCodeFields.trial_days > 0 ? 0 : 7;
    setOfferCodeFields(ocf)
  }

  const handleChangeDiscountType = (e: any, type:string) => {
    const value = e.target.value
    let is_percent = true
    if (value !== 'percentage') {
      is_percent = false
    }
    if (type === 'product') {
      setReferralFields({ ...referralFields, ['product_discount_is_percent']: is_percent })
      setProductDiscountType(value)
    }else if (type === 'subscription') {
      setReferralFields({ ...referralFields, ['subscription_discount_is_percent']: is_percent })
      setSubscriptionDiscountType(value)
    }
  }

  const handleStartDateChange = (e: any) => {
    const date = new Date(e.target.value);
    setOfferCodeFields({ ...offerCodeFields, ['valid_start']: date.getTime() })
  }

  const handleEndDateChange = (e: any) => {
    const date = new Date(e.target.value);
    setOfferCodeFields({ ...offerCodeFields, ['valid_end']: date.getTime() })
  }

  const handleChangeReferral = (e: any) => {
    const value = e.target.value
    setReferralFields({ ...referralFields, [e.target.id]: value })
  }

  const handleChangeOfferCode = (e: any) => {
    const value = e.target.value
    setOfferCodeFields({ ...offerCodeFields, [e.target.id]: value })
  }


  const handleSave = async () => {
    let errors: any = {}
    if (modalType === 'referrals') {

      if (referralFields.firstName === '') {
        errors.firstName = true
      }

      if (referralFields.lastName === '') {
        errors.lastName = true
      }

      const isemailValid = validateEmail(referralFields.email)
      if (!isemailValid) {
        errors.email = true
      }

      setReferralErrors(errors)
      if (Object.keys(errors).length === 0) {

        setModalType('')
      } else {
        setModalType('referrals')
      }
    }else if (modalType === 'offercodes') {
      if (offerCodeFields.id === '') {
        errors.id = true
      }

      setOfferCodeErrors(errors)
      if (Object.keys(errors).length === 0) {
        setModalType('')
      } else {
        setModalType('offercodes')
      }
  }
}


  useEffect(() => {
    const getdata = async () => {
      setIsLoading(true);
      await dispatch(getAllUser({}))
      await dispatch(getAllReferrals({}))
      
      await dispatch(getAllOfferCodes({}))
      //console.log('referrals', referrals)
      //console.log('users', users)
      //console.log('offercodes', offerCodes)
      setIsLoading(false);
      //console.log('testmode', testmode)
    }
    getdata();
  }, []);

  const handleReferral = async (type, action, itemids = []) => {
    if (type === 'users' && action === 'mimic') {
      localStorage.setItem('userId', itemids[0]);
      window.location.replace("/dashboard");
    }

    if (action === 'create') {
      setModalType(type)
    }
    console.log('type', type, 'action', action, 'itemids', itemids)
    //await dispatch(updateReferral({ id, status }))
  }

  const handleProductionMode = async () => {
    window.location.href = ngrokip + '?testmode=production';
  }

  const handleStagingMode = async () => {
    window.location.href = ngrokip +  '?testmode=staging';
  }

  const handleLocalMode = async () => {
    localStorage.setItem('testmode', '')
    window.location.href = 'http://localhost:3000'
  }

  

  return (
    <>
    <div style={{margin:'20px'}}>
     <h1 >Administration Tools</h1>
     {ngrokip && (<div style={{border:'1px, solid, black', padding:'15px', marginBottom:'15px'}}>
      <h3 style={{marginBottom:'10px'}}>Dev Tools</h3>
      {testmode === 'production' ? (
        <>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none', marginRight:'10px'}} onClick={handleStagingMode}>Test in Staging</button>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none'}} onClick={handleLocalMode}>Test with Local Emulators</button>
        </>
      ) : testmode === 'staging' ? (
        <>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none', marginRight:'10px'}} onClick={handleProductionMode}>Test in Production</button>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none'}} onClick={handleLocalMode}>Test with Local Emulators</button>
        </>
      ):(
        <>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none', marginRight:'10px'}} onClick={handleProductionMode}>Test in Production</button>
        <button style={{padding:'10px', backgroundColor:'red', color:'white', border:'none'}} onClick={handleStagingMode}>Test in Staging</button>
        </>
      )} 
      
      </div>)}
     <div style={{border:'1px, solid, black', padding:'15px', marginBottom:'15px'}}>
     <h3 style={{marginBottom:'10px'}}>Users</h3>
        <STable
        headers={[
          { column: "email", label: "Email" },
          { column: "id", label: "Id" },
          { column: "name", label: "Name" },
          { column: "type", label: "User Type" },
          { column: "status", label: "Status" }
        ]}
        data={users || []}
        isLoading={isLoading}
        loadingTag={<h1>Loading...</h1>}
        handleReferral={handleReferral}
        type='users'
      />
</div>
<div style={{border:'1px, solid, black', padding:'15px', marginBottom:'15px'}}>
<h3 style={{marginBottom:'10px'}}>Referrals</h3>
        <STable
        headers={[
          { column: "email", label: "Email" },
          { column: "id", label: "Id" },
          { column: "name", label: "Name" },
          { column: "referred_by", label: "Referred By" },
          { column: "offer_code", label: "Offer Code" },
          { column: "status", label: "Status" }
        ]}
        data={referrals}
        isLoading={isLoading}
        loadingTag={<h1>Loading...</h1>}
        handleReferral={handleReferral}
        type='referrals'
      />
</div>
<div style={{border:'1px, solid, black', padding:'15px', marginBottom:'15px'}}>

<h3 style={{marginBottom:'10px'}}>Offer Codes</h3>
        <STable
        headers={[
          { column: "id", label: "Offer Code" },
          { column: "type", label: "Type" },
          { column: "limit", label: "Redemptions Left" },
          { column: "self_redeemable", label: "Redeemable at Checkout" },
          { column: "product_discount", label: "Product Discount" },
          { column: "subscription_discount", label: "Subscription Discount" },
          { column: "length", label: "Days Applicable" },
          { column: "expiration", label: "Expiration" },
          { column: "staus", label: "Live" }
        ]}
        data={offerCodes}
        isLoading={isLoading}
        loadingTag={<h1>Loading...</h1>}
        handleReferral={handleReferral}
        type='offercodes'
      />
</div>
    </div>
    <Modal show={modalType !== ''} style={{zIndex:10000}} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalType === 'referrals' ? 'Invite a User' : modalType === 'offercodes' ? 'Add An Offer Code' : '' }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form style={{zIndex:10000}}>
          {modalType === 'referrals' ? (
            <>
            <div className='row'>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <label htmlFor='firstName'>First Name*</label>
                  <input
                    type='text'
                    onChange={(e: any) => handleChangeReferral(e)}
                    value={referralFields.firstName}
                    className='form-control'
                    id='firstName'
                    aria-describedby='fnhelp'
                    placeholder='Enter First Name'
                    onKeyDown={handleKeyDown}
                  />
                  {referralErrors.firstName && (
                    <small id='fnhelp' style={{ color: 'red' }} className='form-text'>
                      First Name is required
                    </small>
                  )}
                </div>
              </div>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <label htmlFor='lastName'>Last Name*</label>
                  <input
                    type='text'
                    onChange={(e: any) => handleChangeReferral(e)}
                    value={referralFields.lastName}
                    className='form-control'
                    id='lastName'
                    aria-describedby='lnhelp'
                    placeholder='Enter Last Name'
                    onKeyDown={handleKeyDown}
                  />
                  {referralErrors.lastName && (
                    <small id='lnhelp' style={{ color: 'red' }} className='form-text'>
                      Last Name is required
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <label htmlFor='email'>Email*</label>
                  <input
                    type='email'
                    onChange={(e: any) => handleChangeReferral(e)}
                    value={referralFields.email}
                    className='form-control'
                    id='email'
                    aria-describedby='emailHelp'
                    placeholder='Enter Email'
                    onKeyDown={handleKeyDown}
                  />
                  {referralErrors.email && (
                    <small id='emailHelp' style={{ color: 'red' }} className='form-text'>
                      Enter a valid email address
                    </small>
                  )}
                </div>
              </div>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <label htmlFor='email'>Offer Code</label>
                  <select 
                    onChange={(e: any) => handleChangeReferral(e)}
                    name="offer_code" 
                    id="offer_code"
                    value={referralFields.offer_code}
                    className='form-control'
                    onKeyDown={handleKeyDown}
                  >
                    <option value="">No Offer Code</option>
                    <option value="beta">beta</option>
                    <option value="advanced">advanced</option>
                    <option value="creator">creator</option>
                    { offerCodes.map((item: any) => (
                      <option key={item.id} value={item.id}>{item.id}</option>
                    ))}
                  </select><br></br>
                </div>
              </div>
            </div>
            </>
          ) : modalType === 'offercodes' ? (
            <>
            <div className='row'>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <label htmlFor='firstName'>Offer Code ID* (Must be unique i.e. TWENTY%OFF)</label>
                  <input
                    type='text'
                    onChange={(e: any) => handleChangeOfferCode(e)}
                    value={offerCodes.id}
                    className='form-control'
                    id='id'
                    aria-describedby='fnhelp'
                    placeholder='Enter Offer Code ID'
                    onKeyDown={handleKeyDown}
                  />
                  {offerCodeErrors.id && (
                    <small id='fnhelp' style={{ color: 'red' }} className='form-text'>
                      Offer Code ID is Required
                    </small>
                  )}
                </div>
              </div>
              <div className='row'>
            <div className='col-sm'>
                <div className='form-group mb-3'>
                <Checkbox isChecked={offerCodeFields?.allow_in_checkout} text='Allow Code to be used In Checkout Forms' doCheck={handleAllowCheckout} />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <Checkbox isChecked={offerCodeFields?.trial_days > 0} text='Enable Trial Period' doCheck={() => handleAddTrial()} />
                </div>
              </div>
              </div>
              {
                offerCodeFields.trial_days > 0 && (
                  <div className='row'>
                    <div className='col-sm'>
                      <div className='form-group mb-3'>
                        <label htmlFor='trial_days'>Number of days for free trial before charging.</label>
                        <input
                          type='number'
                          onChange={(e: any) => handleChangeOfferCode(e)}
                          value={offerCodeFields.trial_days}
                          min={0}
                          className='form-control'
                          id='trial_days'
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  </div>
                )
              }
              <div className='row'>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <Checkbox isChecked={limitRedemptions} text='Limit Redemptions' doCheck={handleLimitRedemptions} />
                </div>
              </div>
              </div>
              { limitRedemptions && (<div className='col-sm'>
                <div className='form-group mb-3'>
                  <label htmlFor='lastName'>Maximum Redemptions Allowed</label>
                  <input
                    type='number'
                    onChange={(e: any) => handleChangeOfferCode(e)}
                    value={offerCodeFields.max_redemptions}
                    min={0}
                    className='form-control'
                    id='max_redemptions'
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>)}

            </div>

            <div className='row'>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <label htmlFor='product_discount_is_percent'>Product Discount Type</label>
                  <select 
                    onChange={(e: any) => handleChangeDiscountType(e, 'product')}
                    name="product_discount_type" 
                    id="product_discount_type"
                    value={productDiscountType}
                    className='form-control'
                    onKeyDown={handleKeyDown}
                    >
                    <option value="percentage">Percentage</option>
                    <option value="flat">Flat</option>
                  </select><br></br>
                </div>
              </div>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <label htmlFor='product_discount'>Product Discount in {productDiscountType === 'percentage' ? '%' : '$'}</label>
                  <input
                    type='number'
                    onChange={(e: any) => handleChangeOfferCode(e)}
                    value={offerCodeFields.product_discount}
                    min={0}
                    className='form-control'
                    id='product_discount'
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <label htmlFor='product_discount_is_percent'>Subscription Discount Type</label>
                  <select 
                    onChange={(e: any) => handleChangeDiscountType(e, 'subscription')}
                    name="subscription_discount_type" 
                    id="subscription_discount_type"
                    value={subscriptionDiscountType}
                    className='form-control'
                    onKeyDown={handleKeyDown}
                    >
                    <option value="percentage">Percentage</option>
                    <option value="flat">Flat</option>
                  </select><br></br>
                </div>
              </div>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <label htmlFor='subscription_discount'>Subscription Discount in {subscriptionDiscountType === 'percentage' ? '%' : '$'}</label>
                  <input
                    type='number'
                    onChange={(e: any) => handleChangeOfferCode(e)}
                    value={offerCodeFields.subscription_discount}
                    min={0}
                    className='form-control'
                    id='subscription_discount'
                    onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>
            {
                offerCodeFields.subscription_discount > 0 && (
                  <div className='row'>
                    <div className='col-sm'>
                      <div className='form-group mb-3'>
                        <label htmlFor='cycles'>Number of subscription cycles to discount.</label>
                        <input
                          type='number'
                          onChange={(e: any) => handleChangeOfferCode(e)}
                          value={offerCodeFields.cycles}
                          min={0}
                          className='form-control'
                          id='cycles'
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  </div>
                )
              }
             
            <div className='row'>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <Checkbox isChecked={offerCodeFields?.valid_start > 0} text='Set start date' doCheck={() => handleLimitDates('start')} />
                </div>
              </div>
              </div>
              { offerCodeFields?.valid_start > 0 && (
              <div className='row'>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                <label htmlFor='valid_start'>Offer Code is Valid At</label>
                  <input
                    type="datetime-local"
                     className='form-control'
                    id="valid_start"
                    value={millistoDate(offerCodeFields?.valid_start)}
                    onChange={handleStartDateChange}
                    min={millistoDate(new Date().getTime())}
                  />
                </div>
              </div>
              </div>
              )}
              <div className='row'>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                  <Checkbox isChecked={offerCodeFields?.valid_end > 0} text='Set end date' doCheck={() => handleLimitDates('end')} />
                </div>
              </div>
              </div>
              { offerCodeFields?.valid_end > 0 && (
              <div className='row'>
              <div className='col-sm'>
                <div className='form-group mb-3'>
                <label htmlFor='valid_end'>Offer Code is Valid Until</label>
                  <input
                    type="datetime-local"
                     className='form-control'
                    id="valid_end"
                    value={millistoDate(offerCodeFields?.valid_end)}
                    onChange={handleEndDateChange}
                    min={offerCodeFields?.valid_start > 0 ? millistoDate(offerCodeFields?.valid_start) : millistoDate(new Date().getTime())}
                  />
                </div>
              </div>
              </div>
              )}
             
            </>
          ) : (<></>)}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className='btn btn-secondary'>
            Close
          </button>
          <button onClick={() => handleSave()} className='btn btn-primary'>
            {' '}
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Admintools
