import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Registration } from './Registration';
import { RegistrationBeta } from './RegistrationBeta';
import { getFunctions, httpsCallable } from 'firebase/functions';

export function Regloader() {
  const [searchParams] = useSearchParams();
  const refid = searchParams.get('refid');
  
  // State to hold the beta data and loading state
  const [betaData, setBetaData] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getRefData = async (id: any) => {
        console.log('getBetaData', id);
      const functions = getFunctions();
      const checkReferral = httpsCallable(functions, 'checkReferral');
      
      try {
        const response: any = await checkReferral({ bcode: id });
        
        // Check if there's an error
        if (response.data.result === 'error') {
          setError(true);
        } else {
          setBetaData(response.data.data);
        }
      } catch (err) {
        console.error('Error fetching beta data', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (refid) {
        getRefData(refid);
    } else {
      setLoading(false); // No betaid, show default component
    }
  }, [refid]);

  // Conditionally render based on the state
  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loader component
  }

  if (error || !refid) {
    return <RegistrationBeta />;
  }

  if (betaData) {
    return <Registration betadata={betaData} />;
  }

  return null; // Fallback, should never reach here if logic is correct
}