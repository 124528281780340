/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_4threal/assets/ts/components'
import { useAuth } from '../core/Auth'
import { betaSignup } from '../../../../redux/action/auth'
import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .required('Email is required'),
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
})

export function RegistrationBeta() {
  const [loading, setLoading] = useState(false)
  const { saveAuth } = useAuth()
  const dispatch = useDispatch()
  const baseUrl = `${window.location.protocol}//${window.location.host}`

  const bgTransparent = { background: 'transparent' }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        dispatch(
          betaSignup({
            firstName: values.firstname,
            lastName: values.lastname,
            email: values.email,
            offer_code: 'beta',
            baseUrl: baseUrl
          })
        ).then((res: any) => {
          console.log('Beta signup is under construction');
        })
        .catch((error) => {
          setStatus((error?.response?.data?.message).replace(/"/g, ''))
        })
        setLoading(false)
      } catch (error) {
        saveAuth(undefined)
        setStatus('The registration details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework p-5'
      noValidate id='kt_login_signup_form' onSubmit={formik.handleSubmit}
    >
      <div className='text-start mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Welcome to Xtynded Reality</h1>
        <p className='font-dark1'>Please enter your email to sign up for the beta</p>
      </div>
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='row'>
        <div className='col-12 col-md-6 fv-row mb-8'>
          <label className='form-label fw-semibold font-dark1 fs-6'>First name</label>
          <input
            placeholder='Optional'
            type='text'
            style={bgTransparent}
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'border-red': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'border-primary': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
            onChange={(e) => {
              const inputValue = e.target.value
              if (!inputValue.match(/^[A-Za-z]+$/)) {
                e.target.value = inputValue.replace(/[^A-Za-z]/g, '')
              }
              formik.handleChange(e)
            }}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-12 col-md-6 fv-row mb-8'>
          <label className='form-label fw-semibold font-dark1 fs-6'>Last name</label>
          <input
            placeholder='Optional'
            type='text'
            style={bgTransparent}
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'border-red': formik.touched.lastname && formik.errors.lastname,
              },
              {
                'border-primary': formik.touched.lastname && !formik.errors.lastname,
              }
            )}
            onChange={(e) => {
              const inputValue = e.target.value
              if (!inputValue.match(/^[A-Za-z]+$/)) {
                e.target.value = inputValue.replace(/[^A-Za-z]/g, '')
              }
              formik.handleChange(e)
            }}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-semibold font-dark1 fs-6'>Email address </label>
        <input
          placeholder='Required'
          style={bgTransparent}
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'border-red': formik.touched.email && formik.errors.email },
            {
              'border-primary': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='row align-items-center'>
        <div className='col-12 col-md-6'>
          <Button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 '
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Sign Up</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Sign Up
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </div>
        <div className='col-12 col-md-6 mt-5'>
          <div className='font-dark1 text-center fs-6 '>
            Have an account?{' '}
            <Link to='/auth/login' className='text-decoration-underline font-dark1 fw-semibold'>
              Login
            </Link>
          </div>
        </div>
      </div>
    </form>
  )
}
