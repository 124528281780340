import React from 'react'
import './Checkbox.css'

type StatusProps = {
    isChecked: boolean,
    text?: string;
    doCheck?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function Checkbox({ isChecked, text = 'Show On Card', doCheck }: StatusProps) {
    return (
        <>

            <div className='d-flex align-items-center justify-content-center gap-1'>
                <div className='font-black fw-bold'>{text}</div>
                <div className='video-check-box'>
                    <input onChange={(event) => doCheck && doCheck(event)} type='checkbox' checked={isChecked} />
                </div>
            </div>

        </>
    )
}

export default Checkbox