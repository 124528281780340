/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
// import { toAbsoluteUrl } from '../../../_4threal/helpers' //omitted

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <>
      <div className='container p-5'>
        <img src={require('../../images/logo.png')} alt="" />
      </div>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid justify-content-center'>
        {/* begin::Body */}
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-5 p-md-10 order-2 order-lg-1'>
          {/* begin::Form */}
          <div className='d-flex align-items-center flex-column flex-lg-row-fluid'>
            {/* begin::Wrapper */}
            <div className='w-md-300px   w-lg-500px p-1 p-md-10'>
              <Outlet />
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Form */}

          {/* begin::Footer */}
          <div className='w-75 m-auto'>
            <div className=' d-flex flex-center gap-5 justify-content-sm-center  justify-content-md-between align-items-center flex-wrap px-5'>
              <div style={{ color: "#565656" }}  >
                Copyright © 2024 Xtynded Reality
              </div>


              {/* begin::Links */}
              <div className='d-flex  fw-semibold  fs-base '>
                <a style={{ color: "#565656" }} href='#' className='px-1 ' target='_blank'>
                  Terms
                </a>

                <a style={{ color: "#565656" }} href='#' className='px-1 ' target='_blank'>
                  Privacy
                </a>

                <a style={{ color: "#565656" }} href='#' className='px-1 ' target='_blank'>
                  Contact
                </a>
              </div>
              {/* end::Links */}
            </div>
          </div>
          {/* end::Footer */}
        </div>
        {/* end::Body */}


      </div>
    </>
  )

  // function newFunction() {
  //   return (
  //     <img
  //       className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
  //       src={toAbsoluteUrl('/media/misc/auth-screens.png')}
  //       alt='' />
  //   )
  // }
}

export { AuthLayout }
