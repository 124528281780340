import React, { useState } from "react";

const TableHeader = ({
    headers,
    onSortColumnChange,
    sortColumn,
    sortDirection,
    selectAll,
    handleSelectAllChange
  }) => {
    const handleHeaderClick = (column) => {
      onSortColumnChange(column);
    };

    return (
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
          </th>
          {headers.map((header) => (
            <th
              key={header.column}
              onClick={() => handleHeaderClick(header.column)}
            >
              {header.label}{" "}
              {sortColumn === header.column && (
                <span>{sortDirection === "asc" ? "↑" : "↓"}</span>
              )}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

const TableBody = ({
    headers,
    type,
    data,
    currentPage,
    itemsPerPage,
    sortColumn,
    sortDirection,
    isLoading,
    selectedRows,
    handleRowSelection,
    handleReferral
  }) => {
    const startIdx = (currentPage - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;

    const sortedData = [...data].sort((a, b) => {
      const columnA = a[sortColumn];
      const columnB = b[sortColumn];

      if (columnA < columnB) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (columnA > columnB) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });

    const paginatedData = sortedData.slice(startIdx, endIdx);

    return (
      <tbody>
        {!isLoading &&
          paginatedData.map((item) => (
            <tr key={item.id}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(item.id)}
                  onChange={() => handleRowSelection(item.id)}
                />
              </td>
              {headers.map((header) => (
                <td key={header.column}>{item[header.column]}</td>
              ))}
               <td style={{'width':'45px'}}>
               <div className='position-absolute drop-arrow-hide'>
                    <button className="bg-transparent border-0 dropdown-toggle dropdown-toggle-split more-hover" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                    </button>
                    <ul className="dropdown-menu p-4 mt-3 w-100 px-3">
                      <li className='pt-2 cursor-pointer' onClick={() => handleReferral(type, "edit", [item.id])}>
                        <div className='d-flex gap-2 align-items-center' style={{ width: "max-content" }}>
                          <svg width="16px" height="16px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M13.337 3.997h-0.667v1.333h0.667v5.333h-0.667v1.333h0.667c0.735 0 1.333 -0.598 1.333 -1.333v-5.333c0 -0.735 -0.599 -1.333 -1.333 -1.333m-9.333 2.667H10v2.667H4.003z" /><path d="M11.337 11.997V2.667H13.333V1.333h-5.333v1.333h2.003v1.33h-7.333c-0.735 0 -1.333 0.598 -1.333 1.333v5.333c0 0.735 0.598 1.333 1.333 1.333h7.333V13.333H8v1.333h5.333v-1.333h-1.997zm-8.667 -1.333v-5.333h7.333v5.333z" /></svg>
                          edit
                        </div>
                      </li>
                      {type === 'users' && (
                      <li className='pt-2 cursor-pointer' onClick={() => handleReferral(type, 'mimic', [item.id])}>
                        <div className='d-flex gap-2 align-items-center' style={{ width: "max-content" }}>
                          <svg width="16" height="16" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" ><path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" /></svg>
                          Switch to
                        </div>
                      </li>
                      )}
                    </ul>
                  </div>
            
                
              </td>
            </tr>
          ))}
      </tbody>
    );
  };

const Pagination = ({
  currentPage,
  totalNumberOfPages,
  handlePageChange,
  maxPageNumbers = 5, // Set the maximum number of page numbers to display
}) => {
  const pageNumbers = Array.from(
    { length: totalNumberOfPages },
    (_, index) => index + 1
  );

  const renderPageNumbers = () => {
    if (totalNumberOfPages <= maxPageNumbers) {
      return pageNumbers;
    }

    const middleIndex = Math.floor(maxPageNumbers / 2);

    if (currentPage <= middleIndex) {
      // Display pages from 1 to maxPageNumbers
      return [
        ...pageNumbers.slice(0, maxPageNumbers - 1),
        "...",
        totalNumberOfPages,
      ];
    } else if (currentPage >= totalNumberOfPages - middleIndex) {
      // Display pages from totalNumberOfPages - maxPageNumbers + 2 to totalNumberOfPages
      return [1, "...", ...pageNumbers.slice(-maxPageNumbers + 1)];
    } else {
      // Display pages around the current page
      const startPage = currentPage - middleIndex + 1;
      const endPage = currentPage + middleIndex - 1;
      return [
        1,
        "...",
        ...pageNumbers.slice(startPage, endPage),
        "...",
        totalNumberOfPages,
      ];
    }
  };

  return (
    <div className="row justify-content-between">
      <div className="col-md-3 col-sm-12 text-start">
        Showing page {currentPage} of {totalNumberOfPages} pages
      </div>
      <div className="col-md-3 col-sm-12 text-end">
        <div className="pagination">
          <li className="page-item">
            <button
              className={"page-link " + (currentPage === 1 ? "disabled" : "")}
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >{`<`}</button>
          </li>
          {renderPageNumbers().map((pageNumber, index) => (
            <li key={index} className="page-item">
              <button
                className={`page-link ${
                  currentPage === pageNumber ? "active" : ""
                }`}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            </li>
          ))}
          <li className="page-item">
            <button
              className={
                "page-link " +
                (currentPage === totalNumberOfPages ? "disabled" : "")
              }
              onClick={() => handlePageChange(totalNumberOfPages)}
              disabled={currentPage === totalNumberOfPages}
            >{`>`}</button>
          </li>
        </div>
      </div>
    </div>
  );
};

const STable = ({ headers, data, isLoading, loadingTag, handleReferral, type }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState(""); // Search state
    const [itemsPerPage, setItemsPerPage] = useState(5); // Items per page
    const [sortColumn, setSortColumn] = useState(headers[0].column); // Sorting column
    const [sortDirection, setSortDirection] = useState("asc"); // Sorting direction
    const [selectedRows, setSelectedRows] = useState<number[]>([]); // Selected rows
    const [selectAll, setSelectAll] = useState<boolean>(false); // Select all state

    console.log('data', data)

    const filteredData = data.filter((item) =>
      headers.some((header) =>
        String(item[header.column])
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      )
    );

    const totalNumberOfPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const handleSortColumnChange = (column) => {
      if (sortColumn === column) {
        setSortDirection((prevDirection) =>
          prevDirection === "asc" ? "desc" : "asc"
        );
      } else {
        setSortColumn(column);
        setSortDirection("asc");
      }
    };

    const handleSearchChange = (e) => {
      setSearchValue(e.target.value);
      setCurrentPage(1);
    };

    const handleRowSelection = (id) => {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.includes(id)
          ? prevSelectedRows.filter((rowId) => rowId !== id)
          : [...prevSelectedRows, id]
      );
    };

    const handleSelectAllChange = () => {
      setSelectAll(!selectAll);
      setSelectedRows(
        selectAll ? [] : filteredData.map((item) => item.id)
      );
    };

    // const handleAction = (action) => {
    //   // Perform the action on selected rows
    //   console.log(`Performing action: ${action} on rows: `, selectedRows);
    //   // Reset selection after action
    //   setSelectedRows([]);
    //   setSelectAll(false);
    // };

    return (
      <>

        <div className="row justify-content-between">
          <div className="col-4 text-start">
            <div className="input-group">
              <span className="input-group-text">Show</span>
              <div>
                <select
                  className="form-control form-select"
                  value={itemsPerPage}
                  onChange={(e) => {
                    setItemsPerPage(parseInt(e.target.value, 10));
                    setCurrentPage(1);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
              <span className="input-group-text">entries</span>
            </div>
          </div>
          <div className="col-4 text-center">
          <div className='d-flex justify-content-between gap-3'>
          {type === 'referrals' && (<button type="button" className="btn btn-primary" onClick={() => handleReferral(type, 'create', [])}> Invite User</button>)}
          {type === 'offercodes' && (<button type="button" className="btn btn-primary" onClick={() => handleReferral(type, 'create', [])}>Create New Offer Code</button>)}
          {selectedRows.length > 0 && (
            <>
                {type === 'referrals' && (<button type="button" className="btn btn-primary" onClick={() => handleReferral(type, 'resend', selectedRows)}> Resend/Send Invitations</button>)}
                {type === 'offercodes' && (<button type="button" className="btn btn-primary" onClick={() => handleReferral(type, 'deactivate', selectedRows)}>Deactivate Offer</button>)}
                 </>
            /*
        <DropdownButton id="dropdown-basic-button" title="Download Demo Marker" style={{ float: "right" }}>
            <Dropdown.Item as='button' onClick={() => handleReferral('resend')}>Resend/Send Invitation</Dropdown.Item>
            <Dropdown.Item as='button' onClick={() => handleReferral('download')}>Download Spreadsheet</Dropdown.Item>
        </DropdownButton>*/
        )}
            </div>


          </div>
          <div className="col-4 text-end">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                value={searchValue}
                onChange={handleSearchChange}
                placeholder="Search all columns"
              />
            </div>
          </div>
        </div>
        <br />

        <div className="table-responsive">
          <table className="table table-bordered table-responsive">
            <TableHeader
              headers={headers}
              onSortColumnChange={handleSortColumnChange}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              selectAll={selectAll}
              handleSelectAllChange={handleSelectAllChange}
            />
            <TableBody
              headers={headers}
              type={type}
              data={filteredData}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              isLoading={isLoading}
              selectedRows={selectedRows}
              handleRowSelection={handleRowSelection}
              handleReferral={handleReferral}
            />
          </table>
        </div>

        {isLoading ? (
          <div style={{ textAlign: "center", width: "200px", margin: "0 auto" }}>
            <p>{loadingTag}</p>
          </div>
        ) : (
          ""
        )}

        <Pagination
          currentPage={currentPage}
          totalNumberOfPages={totalNumberOfPages}
          handlePageChange={handlePageChange}
        />
      </>
    );
  };

export default STable;