// src/firebase.ts
// import firebase from 'firebase/app';
// import { getDatabase } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import prodConfig from './firebase.settings.production.json';
import stagingConfig from './firebase.settings.staging.json';
import { AppLog } from './_4threal/helpers';
import LogRocket from 'logrocket';

const localip:any = process.env.REACT_APP_LOCAL_IP;
const ngrokip:any = process.env.REACT_APP_NGROK_FRONTEND_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const searchParams = new URLSearchParams(window.location.search);
let config:any = null;

AppLog('environment', environment);

if (environment === 'production') {
    config = prodConfig;
    LogRocket.init('bt3zpg/4threal');
}else if (environment === 'staging') {
    config = stagingConfig;
}else{
  if (ngrokip && ngrokip === 'https://' + window.location.hostname) {
    let currtestmode = localStorage.getItem('testmode');
    if (searchParams.get("testmode") === 'staging'){
      localStorage.setItem('testmode', 'staging');
      currtestmode = 'staging';
    }else if (searchParams.get("testmode") === 'production'){
      localStorage.setItem('testmode', 'production');
      currtestmode = 'production';
    }
    if (currtestmode === 'staging'){
      config = stagingConfig;
    }else if (currtestmode === 'production'){
      config = prodConfig;
    }
  }else{
    localStorage.setItem('testmode', '');
    config = stagingConfig;
  }
}

  const app = initializeApp(config);
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const storage = getStorage(app);
  const functions = getFunctions(app);
  const analytics = getAnalytics(app);


 if (!environment){
  if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
    connectAuthEmulator(auth, 'http://localhost:9099');        // Auth emulator
    connectFirestoreEmulator(firestore,'localhost', 8080);    // Firestore emulator
    connectStorageEmulator(storage,'localhost', 9199);        // Storage emulator
    connectFunctionsEmulator(functions, 'localhost', 5001);    // Functions emulator
  }

  if (typeof window !== 'undefined' && localip && window.location.hostname === localip) {
    AppLog('run local');
    connectAuthEmulator(auth, `http://${localip}:9099`);        // Auth emulator
    connectFirestoreEmulator(firestore, localip, 8080);    // Firestore emulator
    connectStorageEmulator(storage, localip, 9199);        // Storage emulator
    connectFunctionsEmulator(functions, localip, 5001);    // Functions emulator
  }

  if (typeof window !== 'undefined' && ngrokip && ngrokip === 'https://' + window.location.hostname) {

  }
}


// const isMobileDevice = () => { return /Mobi|Android|iPhone/i.test(navigator.userAgent); }; //omitting due to eslint warning


export { auth, firestore, storage, functions};